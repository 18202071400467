/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { InfoCircleOutlined, SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
  AutoComplete, Button, Dropdown, Form, Modal, Select, Upload, message,
} from 'antd';
import { getBase64, resizeImage } from 'common/common';
import { getTransText } from 'common/translate';
import IcAllImages from 'components/icons/IcAllImages';
import IcAudio from 'components/icons/IcAudio';
import IcClose from 'components/icons/IcClose';
import IcFootage from 'components/icons/IcFootage';
import IcPhotos from 'components/icons/IcPhotos';
import IcUpload from 'components/icons/IcUpload';
import IcVector from 'components/icons/IcVector';
import IcCamera from 'components/icons/SearchBar/IcCamera';
import ICClose from 'components/icons/SearchBar/IcClose';
import { useDebounce } from 'components/layouts/AppLayoutDetail/AppLayoutDefault';
import {
  AI_SEARCH,
  BLACK_COLOR,
  GET_SETTING,
  LANG,
  SEARCH_BAR_ALL,
  SEARCH_BAR_AUDIO,
  SEARCH_BAR_FOOTAGE,
  SEARCH_BAR_PHOTOS,
  SEARCH_BAR_VECTOR,
  SIMILAR_SEARCH_KEYWORD,
  WIDTH_TABLET_768,
} from 'constants/index';
import { LANGUAGE_CODE_DEFAULT } from 'constants/intl';
import { API_ROUTES, ROUTES, ROUTES_ALLOW_SEARCH_BY_IMAGE } from 'constants/routes';
import * as cookie from 'helpers/cookies';
import { getStorage } from 'helpers/storage';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import Axios from 'services/Axios/Axios';
import MainContext from 'stores/context/MainContexts';
import IcArrow from 'components/icons/SearchBar/IcArrow';
import UseWindowSize from 'helpers/CustomHooks/useWindowSize';
import AISearchIntroduction from '../AISearchIntroduction/AISearchIntroduction';

const SearchBar = ({
  isScrollingUp,
  isHome,
  searchKeyword = '',
  inputClassName,
  defaultValueSelector,
  isCheckPortfolio,
  setFilterHidden,
  onAiSearchStatusChange,
}) => {
  const listPlaceholderForSearchBar = {
    all: {
      ai: {
        desktop: getTransText('searchBarPlaceholderAllImageDesktop'),
        tablet: getTransText('searchBarPlaceholderAllImageTablet'),
        mobile: getTransText('searchBarPlaceholderAllImageMobile'),
      },
      DEFAULT: {
        desktop: getTransText('searchBarPlaceholderAllImageDesktop'),
        tablet: getTransText('searchBarPlaceholderAllImageTablet'),
        mobile: getTransText('searchBarPlaceholderAllImageMobile'),
      },
    },
    photography: {
      ai: {
        desktop: getTransText('searchBarPlaceholderPhotoDesktop'),
        tablet: getTransText('searchBarPlaceholderPhotoTablet'),
        mobile: getTransText('searchBarPlaceholderPhotoMobile'),
      },
      DEFAULT: {
        desktop: getTransText('searchBarPlaceholderPhotoDesktop'),
        tablet: getTransText('searchBarPlaceholderPhotoTablet'),
        mobile: getTransText('searchBarPlaceholderPhotoMobile'),
      },
    },
    illustration: {
      ai: {
        desktop: getTransText('searchBarPlaceholderVectorDesktop'),
        tablet: getTransText('searchBarPlaceholderVectorTablet'),
        mobile: getTransText('searchBarPlaceholderVectorMobile'),
      },
      DEFAULT: {
        desktop: getTransText('searchBarPlaceholderVectorDesktop'),
        tablet: getTransText('searchBarPlaceholderVectorTablet'),
        mobile: getTransText('searchBarPlaceholderVectorMobile'),
      },
    },
    footage: {
      ai: {
        desktop: getTransText('searchBarPlaceholderFootageDesktop'),
        tablet: getTransText('searchBarPlaceholderFootageTablet'),
        mobile: getTransText('searchBarPlaceholderFootageMobile'),
      },
      DEFAULT: {
        desktop: getTransText('searchBarPlaceholderFootageDesktop'),
        tablet: getTransText('searchBarPlaceholderFootageTablet'),
        mobile: getTransText('searchBarPlaceholderFootageMobile'),
      },
    },
    audio: {
      ai: {
        desktop: getTransText('searchBarPlaceholderAudioDesktop'),
        tablet: getTransText('searchBarPlaceholderAudioTablet'),
        mobile: getTransText('searchBarPlaceholderAudioMobile'),
      },
      DEFAULT: {
        desktop: getTransText('searchBarPlaceholderAudioDesktop'),
        tablet: getTransText('searchBarPlaceholderAudioTablet'),
        mobile: getTransText('searchBarPlaceholderAudioMobile'),
      },
    },
    free: {
      ai: {
        desktop: getTransText('searchBarPlaceholderFreeDesktop'),
        tablet: getTransText('searchBarPlaceholderFreeTablet'),
        mobile: getTransText('searchBarPlaceholderFreeMobile'),
      },
      DEFAULT: {
        desktop: getTransText('searchBarPlaceholderFreeDesktop'),
        tablet: getTransText('searchBarPlaceholderFreeTablet'),
        mobile: getTransText('searchBarPlaceholderFreeMobile'),
      },
    },
  };

  // detect error when uploading file
  const [errorFileType, setErrorFileType] = useState(false);
  const [visibleMenu, setVisible] = useState(false);
  const router = useRouter();
  const locale = router.query?.lang ?? cookie.getCookies(LANG) ?? LANGUAGE_CODE_DEFAULT;
  const [newInputValue, setNewInputValue] = useState(searchKeyword);
  const [valueSelector, setValueSelector] = useState('');
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [allowSearchOnEnter, setAllowSearchOnEnter] = useState(false);
  const [tempInput, setTempInput] = useState('');
  const [loadingButtonUpload, setLoadingButtonUpload] = useState('');
  const [hasContributorParam, setHasContributorParam] = useState(isCheckPortfolio);
  const [aiSearchActivated, setAiSearchActivated] = useState(
    cookie.getCookies(AI_SEARCH) === 'true' || !cookie.getCookies(AI_SEARCH),
  );
  const [allowAiSearch, setAllowAiSearch] = useState(true);
  const prevAiSearchActivated = useRef();
  const { Option } = Select;
  const [isTablet, setIsTablet] = useState(false);
  const [optionsData, setOptionsData] = useState([]);
  const [openAutoComplete, setOpenAutoComplete] = useState(false);
  const [allowClearButton, setAllowClearButton] = useState(false);
  // get thumbUrl of uploaded image
  const [reverseImageURL, setReverseImageURL] = useState('');
  const { initDataProvider } = useContext(MainContext);
  const [, windowStatus] = UseWindowSize();
  const getSearchBarPlaceholder = () => listPlaceholderForSearchBar?.[valueSelector]?.[aiSearchActivated ? 'ai' : 'DEFAULT']?.[
    windowStatus
  ];
  const context = useContext(MainContext);
  const clientId = context?.initDataProvider?.getSettings?.client_id;
  useEffect(
    () => () => {
      prevAiSearchActivated.current = aiSearchActivated;
    },
    [aiSearchActivated],
  );

  // detect if file is successfully uploaded
  const [uploadedFile, setUploadedFile] = useState(false);
  const valueSearch = useDebounce(newInputValue, 500);
  const { Dragger } = Upload;
  const [form] = Form.useForm();
  // create a list to control uploaded file
  const [fileList, setFileList] = useState([]);
  const inputRef = useRef(null);

  const onCloseMenu = () => {
    setVisible(false);
  };

  const props = {
    name: 'file',
    maxCount: 1,
    beforeUpload: (file) => {
      // clear previously uploaded file
      setFileList([]);
      // hide search button while uploading
      setUploadedFile(false);
      const isJPGorPNF = ['image/jpeg', 'image/png'].includes(file.type);
      // only allow file size less than 5MB
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isJPGorPNF || !isLt5M) {
        setErrorFileType(true);
        if (isTablet) {
          const modal = Modal.error({
            maskClosable: true,
            className: 'modal-error-upload',
            footer: null,
            icon: <ExclamationCircleOutlined />,
            content: (
              <ul className="error-message">
                <li>{getTransText('mustBeJPGorPNG')}</li>
                <li>{getTransText('sizeLessThan5MB')}</li>
              </ul>
            ),
          });
          setTimeout(() => {
            modal.destroy();
          }, 2000);
        }
        return Upload.LIST_IGNORE;
      }
      setErrorFileType(false);
      return isJPGorPNF && isLt5M;
    },
  };

  const onUploadingImage = (info) => {
    setFileList(info?.fileList);
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (url) => {
        resizeImage(url).then((result) => {
          setReverseImageURL(result);
        });
        setUploadedFile(!!info?.fileList?.length);
      });
    }
  };

  const handleClickOpenDragger = () => {
    // 👇️ open file input box on click of another element
    if (inputRef.current?.upload?.uploader) {
      inputRef.current?.upload?.uploader?.onClick();
      if (isOpenSelect) setIsOpenSelect(false);
    }
  };

  const onUploadingImageMobile = (info) => {
    setFileList(info?.fileList);
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, async (url) => {
        await resizeImage(url).then(async (result) => {
          setLoadingButtonUpload(true);
          await Axios.post(
            API_ROUTES.UPLOAD_REVERSE_SEARCH,
            {
              image_base64: result,
            },
            (status, data) => {
              if (data?.content?.fid) {
                // close upload section
                setVisible(false);
                // remove current image
                setFileList([]);
                router.push(`${ROUTES.REVERSE_SEARCH}?fid=${data?.content?.fid}&lang=${locale}`);
              }
            },
          );
          setUploadedFile(false);
          setLoadingButtonUpload(false);
        });
      });
    }
  };

  const reverseSearch = async () => {
    setLoadingButtonUpload(true);
    await Axios.post(
      API_ROUTES.UPLOAD_REVERSE_SEARCH,
      {
        image_base64: reverseImageURL,
      },
      (status, data) => {
        if (data?.content?.fid) {
          // close upload section
          setVisible(false);
          // remove current image
          setFileList([]);
          router.push(`${ROUTES.REVERSE_SEARCH}?fid=${data?.content?.fid}&lang=${locale}`);
        }
      },
    );
    setUploadedFile(false);
    setLoadingButtonUpload(false);
  };

  const menu = (
    <div className="search-bar-box">
      <div className="upload-header">
        <div className="title-box">{getTransText('searchByImage')}</div>
        <Button className="pop-closebtn" onClick={onCloseMenu} icon={<ICClose />} />
      </div>
      <div>
        <span className="box-content">{getTransText('searchByImageMsg')}</span>
        <ul className={`${!errorFileType ? 'display-none' : 'errorMessage'}`}>
          <li>{getTransText('mustBeJPGorPNG')}</li>
          <li>{getTransText('sizeLessThan5MB')}</li>
        </ul>
      </div>
      <Dragger
        {...props}
        customRequest={({ onSuccess }) => setTimeout(() => {
          onSuccess('ok', null);
        }, 0)}
        listType="picture"
        onChange={onUploadingImage}
        fileList={fileList}
        onRemove={() => setUploadedFile(false)}
        className={!errorFileType ? 'ant-upload-drag-custom' : 'ant-upload-drag-custom err'}
      >
        <p className="ant-upload-drag-icon">
          <IcUpload />
        </p>
        <div className="ant-upload-text">
          {getTransText('dragOrDropFile')}
          {' '}
          <a>{getTransText('browse')}</a>
        </div>
      </Dragger>
      {uploadedFile && (
        <div className="search-button-wrapper">
          <Button
            loading={loadingButtonUpload}
            className="btn-search"
            type="primary"
            htmlType="submit"
            size="large"
            onClick={() => reverseSearch()}
          >
            {getTransText('search')}
          </Button>
        </div>
      )}
    </div>
  );

  const onSearch = (values) => {
    if (values) {
      setOpenAutoComplete(false);
      setAllowClearButton(false);
      let keyword = '';
      if (typeof values === 'string') {
        keyword = values;
      } else {
        keyword = values.searchkeyword ?? newInputValue;
      }
      let pathName;
      let aiType;
      if (!isCheckPortfolio || !hasContributorParam) {
        switch (valueSelector) {
          case SEARCH_BAR_FOOTAGE:
            pathName = ROUTES.SEARCH_FOOTAGE;
            break;
          case SEARCH_BAR_AUDIO:
            pathName = ROUTES.SEARCH_AUDIO;
            break;
          case SEARCH_BAR_ALL:
            pathName = ROUTES.SEARCH_IMAGE;
            aiType = 0;
            break;
          case SEARCH_BAR_PHOTOS:
            pathName = ROUTES.SEARCH_IMAGE;
            aiType = 1;
            break;
          case SEARCH_BAR_VECTOR:
            pathName = ROUTES.SEARCH_IMAGE;
            aiType = 2;
            break;
          default:
            break;
        }
      } else {
        pathName = ROUTES.PORTFOLIO;
      }

      let mediaType;
      if (valueSelector === SEARCH_BAR_PHOTOS) {
        mediaType = '1';
      } else if (valueSelector === SEARCH_BAR_VECTOR) {
        mediaType = '2';
      } else if (valueSelector === SEARCH_BAR_ALL) {
        mediaType = null;
      }
      const numPage = '1';
      if (
        [
          ROUTES.SEARCH_IMAGE,
          ROUTES.SEARCH_FOOTAGE,
          ROUTES.SEARCH_AUDIO,
          ROUTES.PORTFOLIO,
        ].includes(router?.pathname)
        && router?.query?.oriKeyword
      ) {
        delete router.query.oriKeyword;
      }
      if (!isCheckPortfolio || !hasContributorParam) {
        // Get current pathname from the URL
        const currentPath = router?.pathname;
        if (pathName === currentPath) {
          // Get current parameters from the URL
          let newQuery;
          if (
            prevAiSearchActivated.current !== undefined
            && aiSearchActivated !== prevAiSearchActivated.current
          ) {
            newQuery = {};
            prevAiSearchActivated.current = aiSearchActivated;
          } else {
            newQuery = router?.query;
          }
          // newQuery = router?.query;

          if (newQuery?.contributorid) delete newQuery.contributorid;
          if (mediaType) {
            newQuery.mediaType = mediaType;
          } else {
            delete newQuery?.mediaType;
          }
          newQuery.keyword = keyword;
          newQuery.page = numPage;
          newQuery.lang = locale;
          if (aiSearchActivated && allowAiSearch && aiType !== undefined) {
            newQuery.aiSearch = 1;
            newQuery.imgtype = aiType;
          } else {
            delete newQuery?.aiSearch;
            delete newQuery?.imgtype;
          }
          router.push({
            pathname: pathName,
            query: newQuery,
          });
        } else {
          const newQuery = { keyword, page: numPage, lang: locale };
          if (aiSearchActivated && allowAiSearch && aiType !== undefined) {
            newQuery.aiSearch = 1;
            newQuery.imgtype = aiType;
          } else {
            delete newQuery?.aiSearch;
            delete newQuery?.imgtype;
          }
          if (mediaType) newQuery.mediaType = mediaType;
          router.push({
            pathname: pathName,
            query: newQuery,
          });
        }
      } else {
        const newQuery = {
          ...router.query,
          keyword,
          page: numPage,
          contributorid: router.query?.contributorid,
          lang: locale,
        };
        if (aiSearchActivated) {
          newQuery.aiSearch = 1;
          newQuery.imgtype = aiType;
        } else {
          delete newQuery?.aiSearch;
          delete newQuery?.imgtype;
        }
        router.push({
          pathname: pathName,
          query: newQuery,
        });
      }
    }
  };

  const focusOnSearchBar = () => {
    setAllowClearButton(true);
  };

  const notFocusOnSearchBar = () => {
    setAllowClearButton(false);
    setOpenAutoComplete(false);
  };

  const onFinishFailed = () => {
    message.info({
      className: 'keywordEmpty-customize',
      content: (
        <div className="content-customize">
          {getTransText('keywordEmpty')}
          .
          <span
            onClick={() => {
              message.destroy();
            }}
            aria-hidden="true"
          >
            <IcClose />
          </span>
        </div>
      ),
      icon: <InfoCircleOutlined />,
      duration: 2,
    });
  };

  useEffect(() => {
    const resizeWindow = () => {
      if (window.innerWidth <= WIDTH_TABLET_768) {
        setIsTablet(true);
      } else {
        setIsTablet(false);
      }
    };

    resizeWindow();

    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  useEffect(() => {
    const handler = (e) => {
      if (!isOpenSelect) return;

      const selectBox = document.querySelector('.select-type-search');
      if (isOpenSelect && !selectBox?.contains(e.target) && selectBox !== e.target) {
        setIsOpenSelect(false);
      }
    };

    window.addEventListener('click', handler);
    return () => window.removeEventListener('click', handler);
  });

  useEffect(() => {
    if (router?.query?.keyword?.includes(SIMILAR_SEARCH_KEYWORD)) {
      setNewInputValue('');
    } else {
      setNewInputValue(router?.query?.keyword);
    }
    form?.resetFields();
  }, [router?.query?.keyword]);

  useEffect(() => {
    setValueSelector(defaultValueSelector);
  }, [router?.query?.mediaType]);
  const apiController = new AbortController();
  const observer = apiController.signal;

  const autoCompleteSearch = () => {
    // set data input when data + white space
    const dataInput = newInputValue.replaceAll(' ', '%20');
    try {
      Axios.getLastApiCall(
        `${API_ROUTES.AUTOCOMPLETE}?keyword=${dataInput}`,
        (status, response) => {
          setOptionsData([...response.content.data]);
        },
        observer,
      );
    } catch (e) {
      // handle error
    }
  };

  const changeValueInSearchBar = (value) => {
    if (value?.trim().length >= 3 && !(value?.indexOf('  ') >= 0 || value?.indexOf(' ') === 0)) {
      setOpenAutoComplete(true);
    }
    setNewInputValue(value);
    if (!allowSearchOnEnter) {
      setAllowSearchOnEnter(true);
    }
  };

  const removeContributor = () => setHasContributorParam(false);

  const onChangeSelect = (value) => {
    setValueSelector(value);
  };
  const onClickSelect = () => {
    setOpenAutoComplete(false);
  };
  const selectAutoCompleteKeyword = (value) => {
    onSearch(value);
  };

  useEffect(() => {
    if (
      newInputValue
      && newInputValue?.trim().length > 2
      && !(newInputValue.indexOf('  ') >= 0 || newInputValue.indexOf(' ') === 0)
    ) {
      autoCompleteSearch();
    } else {
      setOpenAutoComplete(false);
    }
    return () => {
      if (apiController) apiController.abort();
    };
  }, [valueSearch]);

  const dataOption = optionsData.map((dataSearch, index) => ({
    value: dataSearch.value,
    label: (
      <div
        style={{
          display: 'flex',
        }}
        key={index.toString()}
      >
        {newInputValue?.length <= optionsData[0].value.length ? (
          <>
            <span>{dataSearch?.value?.slice(0, newInputValue.length)}</span>
            <span className="color-text-search">
              <span>{dataSearch?.value?.slice(newInputValue?.length)}</span>
            </span>
          </>
        ) : (
          <span className="color-text-search">
            <span>{dataSearch?.value}</span>
          </span>
        )}
      </div>
    ),
  }));

  // detect search bar dropdown
  const detectShowDropdown = initDataProvider?.getSettings?.show_hide_header
   ?? getStorage(GET_SETTING)?.show_hide_header;
  let hideDropdown = true;
  if (detectShowDropdown?.hide_image !== undefined) {
    hideDropdown = (detectShowDropdown.hide_image
        && detectShowDropdown.hide_footage
        && !detectShowDropdown.hide_audio)
      || (detectShowDropdown.hide_image
        && !detectShowDropdown.hide_footage
        && detectShowDropdown.hide_audio);
  }

  const changeAiSearchStatus = () => {
    const nextState = !aiSearchActivated;
    if (setFilterHidden) setFilterHidden(nextState);
    onAiSearchStatusChange?.(nextState);
    cookie.setCookie(AI_SEARCH, nextState);
    setAiSearchActivated(nextState);
  };

  const handleClickOptionModal = (value) => {
    onChangeSelect(value);
    onClickSelect();
    setIsOpenSelect(false);
  };

  useEffect(() => {
    if (valueSelector) {
      onSearch(newInputValue);
    }
  }, [aiSearchActivated]);

  useEffect(() => {
    if (valueSelector) {
      setAllowAiSearch(![SEARCH_BAR_FOOTAGE, SEARCH_BAR_AUDIO].includes(valueSelector));
    }
  }, [valueSelector]);

  useEffect(() => {
    // if cookie exists, check if its value is true. If not, default AI search to true.
    const aiSearchActivate = cookie.getCookies(AI_SEARCH) === 'true' || !cookie.getCookies(AI_SEARCH);
    if (setFilterHidden) setFilterHidden(aiSearchActivate);
    setAiSearchActivated(aiSearchActivate);
  }, []);

  return (
    <div>
      <Form
        form={form}
        className={`${inputClassName}${hasContributorParam ? ' disabled-select' : ''}`}
        onFinish={(e) => onSearch(tempInput || e?.searchkeyword)}
        onFinishFailed={onFinishFailed}
        fields={[
          {
            name: ['searchkeyword'],
            value: newInputValue,
          },
          {
            name: ['selectsearchkeyword'],
            value: valueSelector,
          },
        ]}
      >
        <Form.Item noStyle rules={[{ required: true }]} name="searchkeyword">
          {allowAiSearch && (
            <AISearchIntroduction
              aiSearchActivated={aiSearchActivated}
              changeAiSearchStatus={changeAiSearchStatus}
            />
          )}
          <AutoComplete
            style={{ flex: '1' }}
            dropdownClassName={`search-bar-auto-complete-dropdown ${
              isHome
                ? 'auto-complete-home'
                : isScrollingUp
                  ? 'dropdown-autocomplete'
                  : 'search-bar-autocomplete'
            }`}
            dropdownMatchSelectWidth={false}
            options={dataOption}
            onChange={changeValueInSearchBar}
            backfill
            open={openAutoComplete}
            onSelect={selectAutoCompleteKeyword}
            onKeyUp={(e) => (e.key === 'Enter' && openAutoComplete
              ? onSearch(newInputValue)
              : setTempInput(e.target.value))}
            onBlur={notFocusOnSearchBar}
            onFocus={focusOnSearchBar}
            placeholder={getSearchBarPlaceholder()}
            allowClear={allowClearButton}
            defaultValue={newInputValue}
            getPopupContainer={(node) => node.parentElement.parentElement}
          />
          {router.query.contributorid && hasContributorParam && (
            <div
              style={{ display: router.query.contributorid ? 'flex' : 'none' }}
              className="search-contributor"
              aria-hidden="true"
            >
              <p className="value-contributor">{router.query.contributorid}</p>
              <span
                onClick={removeContributor}
                className="value-contributor remove-contributor-icon"
                role="button"
                aria-hidden="true"
              >
                <ICClose />
              </span>
            </div>
          )}
        </Form.Item>
        <Form.Item noStyle name="camerasearch">
          {ROUTES_ALLOW_SEARCH_BY_IMAGE.includes(router?.pathname)
          && clientId && clientId !== 5479 && (
            <Dropdown
              overlay={menu}
              trigger={['click']}
              visible={visibleMenu}
              disabled={hasContributorParam}
              onVisibleChange={(visible) => setVisible(visible)}
            >
              <div
                onClick={(e) => e.preventDefault()}
                className="camera-search-icon pl-1"
                aria-hidden="true"
              >
                <IcCamera width="32" height="32" fillcolor={BLACK_COLOR} />
              </div>
            </Dropdown>
          )}
        </Form.Item>
        {valueSelector && !hideDropdown && (
          <Form.Item noStyle name="selectsearchkeyword" initialValue={valueSelector}>
            <Select
              onChange={onChangeSelect}
              removeIcon="true"
              suffixIcon={<IcArrow />}
              className={`select-type-search ${!isOpenSelect ? 'no-border' : 'select-up-arrow'}`}
              onClick={() => {
                setIsOpenSelect((prev) => !prev);
                onClickSelect();
              }}
              open={isOpenSelect}
              showArrow
              disabled={hasContributorParam}
              dropdownClassName="dropdown-search-bar"
              dropdownStyle={isTablet ? { display: 'none' } : {}}
            >
              {!detectShowDropdown?.hide_image ? (
                <>
                  <Option value="all">
                    <div className="option-dropdown-search-bar">
                      <IcAllImages />
                      <span className="option-dropdown-search-bar-span">
                        {getTransText('allImages')}
                      </span>
                    </div>
                  </Option>
                  <Option value="photography">
                    <div className="option-dropdown-search-bar">
                      <IcPhotos />
                      <span className="option-dropdown-search-bar-span">
                        {getTransText('photos')}
                      </span>
                    </div>
                  </Option>
                  <Option value="illustration">
                    <div className="option-dropdown-search-bar">
                      <IcVector />
                      <span className="option-dropdown-search-bar-span">
                        {getTransText('vectors')}
                      </span>
                    </div>
                  </Option>
                </>
              ) : null}
              {(detectShowDropdown?.hide_footage && detectShowDropdown?.hide_audio)
              || detectShowDropdown?.hide_image ? null : (
                <Option className="search-filter-separator" />
                )}
              {!detectShowDropdown?.hide_footage ? (
                <Option value="footage">
                  <div className="option-dropdown-search-bar">
                    <IcFootage />
                    <span className="option-dropdown-search-bar-span">
                      {getTransText('footage')}
                    </span>
                  </div>
                </Option>
              ) : null}
              {!detectShowDropdown?.hide_audio ? (
                <Option value="audio">
                  <div className="option-dropdown-search-bar">
                    <IcAudio />
                    <span className="option-dropdown-search-bar-span">{getTransText('audio')}</span>
                  </div>
                </Option>
              ) : null}
            </Select>
          </Form.Item>
        )}
        <Form.Item className="btn-search">
          <Button type="primary" htmlType="submit" icon={<SearchOutlined />} size="large" />
        </Form.Item>
      </Form>

      {isTablet && (
        <Modal
          title={getTransText('options')}
          visible={isOpenSelect}
          wrapClassName={`modal-option-dropdown-search-wrap ${isOpenSelect ? 'active' : ''}`}
          className="modal-option-dropdown-search"
          footer={null}
          transitionName=""
          forceRender
          maskClosable
          destroyOnClose
          onCancel={() => setIsOpenSelect(false)}
        >
          <div>
            <div
              onClick={handleClickOpenDragger}
              className="option-dropdown-search-bar-upload"
              aria-hidden="true"
            >
              <Dragger
                {...props}
                ref={inputRef}
                customRequest={({ onSuccess }) => setTimeout(() => {
                  onSuccess('ok', null);
                }, 0)}
                listType="picture"
                onChange={onUploadingImageMobile}
                onRemove={() => setUploadedFile(false)}
                className={!errorFileType ? 'ant-upload-drag-custom' : 'ant-upload-drag-custom err'}
              />
              <IcCamera width="20" height="20" fillcolor={BLACK_COLOR} />
              <span className="option-dropdown-search-bar-upload-span">
                {getTransText('searchByImage')}
              </span>
            </div>
            <div className="option-dropdown-search-bar-separator" />
            <div className="list-option-dropdown-search-bar">
              {!detectShowDropdown?.hide_image ? (
                <>
                  <div
                    className={`option-dropdown-search-bar ${
                      valueSelector === 'all' ? 'select' : ''
                    }`}
                    onClick={() => handleClickOptionModal('all')}
                    aria-hidden="true"
                  >
                    <IcAllImages />
                    <span className="option-dropdown-search-bar-span">
                      {getTransText('allImages')}
                    </span>
                  </div>
                  <div
                    className={`option-dropdown-search-bar ${
                      valueSelector === 'photography' ? 'select' : ''
                    }`}
                    onClick={() => handleClickOptionModal('photography')}
                    aria-hidden="true"
                  >
                    <IcPhotos />
                    <span className="option-dropdown-search-bar-span">
                      {getTransText('photos')}
                    </span>
                  </div>
                  <div
                    className={`option-dropdown-search-bar ${
                      valueSelector === 'illustration' ? 'select' : ''
                    }`}
                    onClick={() => handleClickOptionModal('illustration')}
                    aria-hidden="true"
                  >
                    <IcVector />
                    <span className="option-dropdown-search-bar-span">
                      {getTransText('vectors')}
                    </span>
                  </div>
                </>
              ) : null}
              {(detectShowDropdown?.hide_footage && detectShowDropdown?.hide_audio)
              || detectShowDropdown?.hide_image ? null : (
                <div className="option-dropdown-search-bar-separator" />
                )}
              {!detectShowDropdown?.hide_footage ? (
                <div
                  className={`option-dropdown-search-bar ${
                    valueSelector === 'footage' ? 'select' : ''
                  }`}
                  onClick={() => handleClickOptionModal('footage')}
                  aria-hidden="true"
                >
                  <IcFootage />
                  <span className="option-dropdown-search-bar-span">{getTransText('footage')}</span>
                </div>
              ) : null}
              {!detectShowDropdown?.hide_audio ? (
                <div
                  className={`option-dropdown-search-bar ${
                    valueSelector === 'audio' ? 'select' : ''
                  }`}
                  onClick={() => handleClickOptionModal('audio')}
                  aria-hidden="true"
                >
                  <IcAudio />
                  <span className="option-dropdown-search-bar-span">{getTransText('audio')}</span>
                </div>
              ) : null}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
SearchBar.propTypes = {
  isScrollingUp: PropTypes.bool,
  isHome: PropTypes.bool,
  searchKeyword: PropTypes.string,
  defaultValueSelector: PropTypes.string,
  inputClassName: PropTypes.string,
  isCheckPortfolio: PropTypes.bool,
  setFilterHidden: PropTypes.func,
  onAiSearchStatusChange: PropTypes.func,
};

SearchBar.defaultProps = {
  isScrollingUp: false,
  isHome: false,
  searchKeyword: '',
  defaultValueSelector: '',
  inputClassName: 'search-bar-form',
  isCheckPortfolio: false,
  setFilterHidden: null,
  onAiSearchStatusChange: null,
};

export default SearchBar;
