/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

const IcAiSearchActive = ({
  fillcolor,
  width,
  height,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 36 32" fill="none">
    <rect width="36.0004" height="32" rx="4" fill="#F0BA27" />
    <path d="M26.3735 8.00002C25.5193 8.00472 24.8307 8.701 24.8354 9.55522L24.907 22.4618C24.9117 23.316 25.608 24.0047 26.4622 24C27.3165 23.9953 28.0051 23.299 28.0004 22.4448L27.9288 9.53823C27.9241 8.68401 27.2278 7.99533 26.3735 8.00002Z" fill="white" />
    <path d="M16.439 21.3629C17.2722 21.3629 17.9476 20.7184 17.9476 19.9234C17.9476 19.1284 17.2722 18.4839 16.439 18.4839C15.6058 18.4839 14.9304 19.1284 14.9304 19.9234C14.9304 20.7184 15.6058 21.3629 16.439 21.3629Z" fill="white" />
    <path d="M16.4868 12.7039L21.9316 23.0673C22.3286 23.823 23.2643 24.116 24.0215 23.7219C24.7787 23.3277 25.0707 22.3956 24.6737 21.6399L17.9524 8.84691C17.6897 8.34703 17.1914 8.04957 16.665 8.01955C16.0346 7.92425 15.3839 8.22638 15.0653 8.81916L8.18416 21.6216C7.78003 22.3735 8.0632 23.3083 8.81664 23.7095C9.57009 24.1108 10.5085 23.8265 10.9126 23.0746L16.4868 12.7039Z" fill="white" />
  </svg>
);
IcAiSearchActive.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcAiSearchActive.defaultProps = {
  fillcolor: '#F0BA27',
  width: '38',
  height: '32',
};
export default IcAiSearchActive;
