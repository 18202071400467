import { useState, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { WIDTH_MOBILE, WIDTH_TABLET_768 } from 'constants/index';

function UseWindowSize() {
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowWidth(window?.innerWidth);
    }, 100);

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const windowStatus = useMemo(() => {
    switch (true) {
      case windowWidth > WIDTH_TABLET_768:
        return 'desktop';
      case WIDTH_MOBILE < windowWidth && windowWidth <= WIDTH_TABLET_768:
        return 'tablet';
      case windowWidth <= WIDTH_MOBILE:
        return 'mobile';
      default:
        return 'desktop';
    }
  }, [windowWidth]);
  return [windowWidth, windowStatus];
}

export default UseWindowSize;
