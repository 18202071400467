import PropTypes from 'prop-types';
import { ICON_COLOR_DEFAULT } from 'constants/index';

const IcCamera = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M24 21.5C22.0706 21.5 20.5 23.0706 20.5 25C20.5 26.9294 22.0706 28.5 24 28.5C25.9294 28.5 27.5 26.9294 27.5 25C27.5 23.0706 25.9294 21.5 24 21.5ZM24 32C20.1395 32 17 28.8605 17 25C17 21.1395 20.1395 18 24 18C27.8605 18 31 21.1395 31 25C31 28.8605 27.8605 32 24 32Z" fill={fillcolor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.28795 14.8889C8.87341 14.8889 8.45455 15.1024 8.45455 15.5821V34.8141C8.45455 35.3015 8.87427 35.5556 9.28795 35.5556H38.712C39.1257 35.5556 39.5455 35.3015 39.5455 34.8141V15.5821C39.5455 15.1024 39.1275 14.8889 38.712 14.8889H33.4914C32.3531 14.8889 31.2226 14.3894 30.39 13.518L28.642 11.6425C28.4866 11.4815 28.2353 11.4444 28.0539 11.4444H19.947C19.7103 11.4444 19.4979 11.5323 19.3459 11.6899L17.6074 13.4999C16.7636 14.3808 15.634 14.8889 14.5086 14.8889H9.28795ZM38.712 39H9.28795C6.88445 39 5 37.1615 5 34.8141V15.5821C5 13.2622 6.88445 11.4444 9.28795 11.4444H14.5086C14.6822 11.4444 14.9232 11.3136 15.1106 11.1181L16.8482 9.31147C17.641 8.48136 18.7724 8 19.947 8H28.0539C29.2647 8 30.3659 8.45553 31.157 9.28133L32.905 11.1568C33.0682 11.3282 33.2996 11.4444 33.4914 11.4444H38.712C41.1164 11.4444 43 13.2622 43 15.5821V34.8141C43 37.1615 41.1164 39 38.712 39Z" fill={fillcolor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M36 20.5C36 21.328 35.328 22 34.5 22C33.672 22 33 21.328 33 20.5C33 19.672 33.672 19 34.5 19C35.328 19 36 19.672 36 20.5Z" fill={fillcolor} />
  </svg>
);
IcCamera.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcCamera.defaultProps = {
  fillcolor: ICON_COLOR_DEFAULT,
  width: '48',
  height: '48',
};
export default IcCamera;
