import { useState, useEffect, useCallback } from 'react';
import { Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { getTransText } from 'common/translate';
import * as cookie from 'helpers/cookies';
import IcAiSearchActive from 'components/icons/SearchBar/IcAiSearchActive';
import IcAiSearchInactive from 'components/icons/SearchBar/IcAiSearchInactive';
import { HIDE_AI_INTRODUCTION } from 'constants/index';

const AISearchIntroduction = ({ aiSearchActivated, changeAiSearchStatus }) => {
  const [showIntroduction, setShowIntroduction] = useState(false);
  useEffect(() => {
    const hideIntroductionStatus = cookie.getCookies(HIDE_AI_INTRODUCTION) === 'true';
    setShowIntroduction(!hideIntroductionStatus);
  }, []);
  const hideIntroduction = useCallback(() => {
    setShowIntroduction(false);
    cookie.setCookie(HIDE_AI_INTRODUCTION, true, { expires: 30 });
  }, []);
  return (
    <div className={`ai-search-introduction-container${showIntroduction ? ' show-layer' : ''}`}>
      <Tooltip
        placement="bottomLeft"
        title={getTransText(
          aiSearchActivated ? 'aiPoweredSearchOn' : 'aiPoweredSearch',
        )}
      >
        <div
          aria-hidden
          onClick={changeAiSearchStatus}
          className="search-bar-ai-search"
        >
          {aiSearchActivated ? <IcAiSearchActive /> : <IcAiSearchInactive />}
        </div>
      </Tooltip>
      {showIntroduction && (
        <div className="ai-search-introduction-dialog">
          <p className="new-feature-notice">{getTransText('New')}</p>
          <p className="ai-powered-search">{getTransText('aiPoweredSearch')}</p>
          <p className="ai-search-introduction-des">
            {getTransText('aiSearchIntroduction')}
          </p>
          <Button
            onClick={hideIntroduction}
            className="global-btn primary-btn ai-search-introduction-btn"
          >
            {getTransText('ok')}
          </Button>
        </div>
      )}
    </div>
  );
};

AISearchIntroduction.propTypes = {
  aiSearchActivated: PropTypes.bool,
  changeAiSearchStatus: PropTypes.func,
};

AISearchIntroduction.defaultProps = {
  aiSearchActivated: true,
  changeAiSearchStatus: () => {},
};

export default AISearchIntroduction;
