/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

const IcAiSearchInactive = ({
  fillcolor,
  width,
  height,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 36 32" fill="none">
    <rect width="36" height="32" rx="4" fill="#F9F9F9" />
    <path d="M26.3733 8.00002C25.5191 8.00472 24.8304 8.701 24.8352 9.55522L24.9067 22.4618C24.9115 23.316 25.6078 24.0047 26.462 24C27.3162 23.9953 28.0049 23.299 28.0001 22.4448L27.9286 9.53823C27.9238 8.68401 27.2275 7.99533 26.3733 8.00002Z" fill="#A3A3A3" />
    <path d="M16.439 21.3629C17.2722 21.3629 17.9476 20.7184 17.9476 19.9234C17.9476 19.1284 17.2722 18.4839 16.439 18.4839C15.6058 18.4839 14.9304 19.1284 14.9304 19.9234C14.9304 20.7184 15.6058 21.3629 16.439 21.3629Z" fill="#A3A3A3" />
    <path d="M16.4867 12.704L21.9314 23.0673C22.3285 23.823 23.2642 24.116 24.0214 23.7219C24.7786 23.3277 25.0705 22.3956 24.6735 21.6399L17.9522 8.84691C17.6896 8.34711 17.1914 8.04967 16.6651 8.01956C16.0347 7.92422 15.3839 8.22635 15.0653 8.81916L8.18416 21.6216C7.78003 22.3735 8.0632 23.3083 8.81664 23.7095C9.57009 24.1108 10.5085 23.8265 10.9126 23.0746L16.4867 12.704Z" fill="#A3A3A3" />
  </svg>
);
IcAiSearchInactive.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcAiSearchInactive.defaultProps = {
  fillcolor: '#F9F9F9',
  width: '38',
  height: '32',
};
export default IcAiSearchInactive;
